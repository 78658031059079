import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import locomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

// Code to remove preloader
const preloader = document.getElementById("preloader");
window.addEventListener("load", () => {
  preloader.style.display = "none";
});

// Make sure the page is loaded before adding animations
window.addEventListener("load", () => {
  // Code for the responsive navbar to work
  const hamburger = document.getElementById("hamburger");
  const navMenu = document.getElementById("navMenu");
  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("hamburger--active");
    navMenu.classList.toggle("navbar__menu--active");
  });

  // Locomotive scroll
  const scrollElement = document.querySelector("[data-scroll-container]");
  const locoScroll = new locomotiveScroll({
    el: scrollElement,
    smooth: true,
    lerp: 0.08,
    tablet: {
      breakpoint: 768,
    },
  });

  setTimeout(() => {
    locoScroll.update();
  }, 1000);

  // Linking locomotive scroll with scrollTrigger
  locoScroll.on("scroll", ScrollTrigger.update);

  ScrollTrigger.scrollerProxy(locoScroll.el, {
    scrollTop(value) {
      return arguments.length
        ? locoScroll.scrollTo(value, 0, 0)
        : locoScroll.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        left: 0,
        top: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    pinType: locoScroll.el.style.transform ? "transform" : "fixed",
  });

  // Getting the width of the window
  let windowWidth = window.innerWidth;
  window.addEventListener("resize", (e) => {
    windowWidth = window.windowWidth;
  });

  const isMobile = () => {
    return windowWidth < 992 ? true : false;
  };

  // GSAP animations
  const heroTL = gsap.timeline();
  heroTL.from(".navbar__brand img", {
    x: -30,
    opacity: 0,
    duration: 1.2,
    ease: "power4.out",
  });

  if (!isMobile()) {
    heroTL.from(
      ".navbar__listItem",
      {
        x: -30,
        opacity: 0,
        duration: 1.2,
        ease: "power4.out",
        stagger: 0.1,
      },
      "-=0.75"
    );
  }

  heroTL.from(
    ".hero__heading",
    {
      y: 50,
      duration: 0.5,
      opacity: 0,
      ease: "power4.out",
      // scrollTrigger: ".hero__heading",
    },
    "-=1"
  );

  heroTL.from(
    ".socials__listItem",
    {
      y: 15,
      opacity: 0,
      stagger: 0.1,
    },
    "-=0.95"
  );

  heroTL.from(
    ".hero__textBox p, .hero__linkWrapper",
    {
      y: 15,
      opacity: 0,
      ease: "power4.out",
      duration: 1.5,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".hero__textBox",
      },
    },
    "-=0.5"
  );

  heroTL.to(".hero__linkWrapper img", {
    x: 15,
    duration: 1,
    ease: "power4.in",
    repeat: -1,
    opacity: 0,
  });

  gsap.from(".hero__quoteWrapper", {
    y: 60,
    duration: 1.5,
    opacity: 0,
    ease: "power4.out",
    delay: 0.15,
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".hero__quoteWrapper",
    },
  });

  // Sections animations
  gsap.utils.toArray(".color").forEach(function (elem) {
    var color = elem.getAttribute("data-color");

    ScrollTrigger.create({
      trigger: elem,
      scroller: locoScroll.el,
      start: "top 50%",
      end: "bottom 50%",
      onEnter: () => gsap.to("main", { backgroundColor: color }),
      onLeave: () => gsap.to("main", { backgroundColor: "initial" }),
      onLeaveBack: () => gsap.to("main", { backgroundColor: "initial" }),
      onEnterBack: () => gsap.to("main", { backgroundColor: color }),
      // markers: true,
    });
  });

  // Interests section animations
  gsap.from(".interests__header h2", {
    y: 60,
    duration: 1,
    opacity: 0,
    ease: "power4.out",
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".interests__header h2",
      start: "top 90%",
    },
  });

  gsap.from(".interests__guide", {
    y: 10,
    duration: 0.5,
    opacity: 0,
    ease: "power4.out",
    stagger: 0.2,
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".interests__guides",
      start: "top center",
    },
  });

  gsap.from(".interest", {
    y: 20,
    duration: 0.4,
    opacity: 0,
    ease: "power4.out",
    stagger: 0.1,
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".interest",
      // start: "top center"
    },
  });

  // VECTORS SECTION
  gsap.from(".vectors__heading h2", {
    y: 80,
    duration: 1,
    opacity: 0,
    ease: "power4.out",
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".vectors__heading h2",
      start: "top 80%",
    },
  });

  gsap.from(".vector", {
    y: 50,
    duration: 0.7,
    opacity: 0,
    ease: "power4.out",
    stagger: 0.25,
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".vector",
      start: "top 70%",
    },
  });

  // Values section animation
  // const valuesTL = gsap.timeline();
  gsap.from(".values__heading h2", {
    y: 80,
    duration: 0.8,
    opacity: 0,
    ease: "power4.out",
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".values__heading h2",
      start: "top 90%",
    },
  });

  gsap.from(".value", {
    y: 50,
    duration: 0.7,
    opacity: 0,
    ease: "power4.out",
    stagger: 0.25,
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".value",
      start: "top 80%",
    },
  });

  gsap.from(".values__bigText", {
    y: 80,
    duration: 0.8,
    opacity: 0,
    ease: "power4.out",
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".values__bigText",
      start: "top 80%",
    },
  });

  // CONTACT ANIMATIONS

  gsap.from(".contact__text", {
    x: -50,
    duration: 0.8,
    opacity: 0,
    ease: "power4.out",
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".contact__text",
      start: "top 80%",
    },
  });

  gsap.to(".contact__icon img", {
    x: 35,
    duration: 0.5,
    ease: "power4.in",
    yoyo: true,
    repeat: -1,
    opacity: 0,
    direction: "alternate",
    scrollTrigger: {
      scroller: locoScroll.el,
      trigger: ".contact__icon",
      start: "top 80%",
    },
  });

  const contactTL = gsap.timeline();

  contactTL.from(".footer__copy p, .footer__createdBy p", {
    x: -50,
    duration: 0.8,
    opacity: 0,
    ease: "power4.out",
    stagger: 0.3,
  });

  ScrollTrigger.create({
    trigger: ".footer__copy",
    animation: contactTL,
    scroller: locoScroll.el,
  });

  ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
  ScrollTrigger.refresh();
});
